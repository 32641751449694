import React, { FC, useContext, useEffect, useCallback, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import { Form, Input, Select } from 'formik-antd'
import * as Yup from 'yup'

import UserService from 'services/UserService'
import AnalyticsService from 'services/AnalyticsService'
import { UserStoreContext } from 'stores/UserStore'
import { Button } from 'components'

import './style.scss'

type ProfileFormProps = {
  onProfileSave?: () => void
}

const CFA_LEVEL_1_PERIOD_LIST = [
  'Feb 2023',
  'May 2023',
  'Aug 2023',
  'Nov 2023',
  'Not scheduled',
]

const PROFESSION_LIST = [
  'Student',
  'Accountant',
  'Trader',
  'Business Analyst',
  'Portfolio Manager',
]

const ProfileSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  occupation: Yup.string().required('Required'),
  cfaExamPeriod: Yup.string().required('Required'),
})

const ProfileForm: FC<ProfileFormProps> = ({ onProfileSave }) => {
  const [profileInfo, setProfileInfo] = useState({} as IProfile)
  const UserStore = useContext(UserStoreContext)

  useEffect(() => {
    const getAndStoreProfileInfo = async () => {
      const userProfile = await UserService.getProfile()
      if (userProfile) {
        setProfileInfo(userProfile)
        updateStoreProfile(userProfile)
      }
    }
    // Fetch profile info from BE
    // and populate if it exists
    getAndStoreProfileInfo()
  }, [])

  const updateStoreProfile = useCallback(
    (profile: IProfile) => {
      AnalyticsService.identify(UserStore.uid, {
        cfaPeriod: profile.cfaExamPeriod,
      })
      UserStore.setProfile(profile)
    },
    [UserStore.profile],
  )

  const onFormSubmit = async (
    values: IProfile,
    { setSubmitting }: FormikHelpers<IProfile>,
  ) => {
    setSubmitting(true)
    try {
      const userMD = await UserService.updateProfile(values)
      updateStoreProfile(userMD.profile)
      setSubmitting(false)
      onProfileSave && onProfileSave()
    } catch (err) {
      setSubmitting(true)
    }
  }

  return (
    <Formik
      initialValues={profileInfo}
      validationSchema={ProfileSchema}
      onSubmit={onFormSubmit}
      enableReinitialize
    >
      {({ touched, errors, isValid, isSubmitting, values }) => (
        <Form className="profile-form">
          <div className="input-container">
            <p>What shall we call you?</p>
            <Input
              className="input"
              name="name"
              type="text"
              placeholder="CFA Candidate"
            />
            {errors.name && touched.name && (
              <div className="error-message">C'mon, don't be shy!</div>
            )}
          </div>
          <div className="input-container">
            <p>What is your occupation?</p>
            <Select
              name="occupation"
              showArrow={false}
              placeholder="Select one"
            >
              {PROFESSION_LIST.map((profession) => (
                <Select.Option value={profession}>{profession}</Select.Option>
              ))}
            </Select>
          </div>
          <div className="input-container">
            <p>When's your CFA Level 1 exam?</p>
            <Select
              name="cfaExamPeriod"
              showArrow={false}
              placeholder="Select your exam period"
            >
              {CFA_LEVEL_1_PERIOD_LIST.map((period) => (
                <Select.Option value={period}>{period}</Select.Option>
              ))}
            </Select>
          </div>
          <div className="description">
            <span className="text">
              * You can update this information in your Profile section anytime.
            </span>
          </div>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            disabled={!isValid || !values.name}
            style={{
              marginTop: 16,
            }}
          >
            SAVE
          </Button>
        </Form>
      )}
    </Formik>
  )
}
export default ProfileForm
